import './css/Rental.css';

export default function Rental() {

    return (
    <div className="flex-container">
        <div className="item">One</div>
        <div className="item">Two</div>
        <div className="item">Three</div>
        <div className="item">Four</div>
        <div className="item">Five</div>
        <div className="item">Six</div>
        <div className="item">Seven</div>
        <div className="item">Eight</div>
        <div className="item">Nine</div>
        <div className="item">Ten</div>
    </div>
    );

}

