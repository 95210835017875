import { Link } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faKey, faKeyboard, faLocation, faHamburger } from "@fortawesome/free-solid-svg-icons";

import './Footer.css';

import * as constants from "../../../core/constants";


export default function Footer({isMenuShown, setIsMenuShown}) {

    const burgerStyle = {
        backgroundColor: constants.DARK_THEME_TEXT_COLOR,
        width: "100%",
        height: "5px",
        borderRadius: "1px",
        display: "block",
        margin: "3px 0"
      };

    const onSelectStyle = {
        backgroundColor: constants.DARK_THEME_SELECTED_ELEMENT_COLOR   
    };

    const noSelectionStyle = {
        backgroundColor: constants.DARK_THEME_NO_SELECT_ELEMENT_COLOR
    }

    const handleToggle = () => {
        if (isMenuShown){
            setIsMenuShown(false);
        }
        else{
        setIsMenuShown(true);
        }
    };

    return ( 
        <div className="footer-flex-container"> 
            <footer class="site-footer">
            <div class="container">
                <div class="flex-row">
                    <div class="flex-3 footer-item-align-left">
                        <h6>About</h6>
                        <p class="text-justify"> ReraLabs.com <i>WANTS TO HELP</i> real-estate investors with price-trends. 
                        The aim is to help investors compare their investments in Dubai Real Estate versus Gold.
                        Help investors make a data-driven decision, allow them to compare different neighbourhoods in Dubai, 
                        show the price-trend compared to Gold, in prime locations like Jumeirah, Dubai Sports City, 
                        Dubai International Airpot, Deira, Dubai Silicon Oasis, Al Warsan, Dubai Downtown, Business Bay, Dubai Palm Islands.
                        </p>
                        <p>
                        Subscribed users also get access to monthly price trends, and can make BUY or SELL decisions based on past trends. 
                        This is not investment advice*. We suggest that you get in touch with registered Real Estate Agents for more details. </p>
                    </div>

                    <div class="flex-3 footer-item-align-center">
                        <h6>Categories</h6>
                        <ul class="footer-links">
                        <li><a href="http://reralabs.com">Area-wise Trends</a></li>
                        <li><a href="http://reralabs.com">Monthly Trends</a></li>
                        <li><a href="http://reralabs.com">Trend near Landmarks</a></li>
                        <li><a href="http://reralabs.com">Upcoming Projects</a></li>
                        <li><a href="http://reralabs.com">Registered Real Estate</a></li>
                        <li><a href="http://reralabs.com">Monthly NewsLetter</a></li>
                        </ul>
                    </div>

                    <div class="flex-3 footer-item-align-right">
                        <h6>Quick Links</h6>
                        <ul class="footer-links">
                        <li><a href="http://reralabs.com">About Us</a></li>
                        <li><a href="http://reralabs.com">Contact Us</a></li>
                        <li><a href="http://reralabs.com">Contribute</a></li>
                        <li><a href="http://reralabs.com">Privacy Policy</a></li>
                        {/* <li><a href="http://scanfcode.com/sitemap/">Sitemap</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container-social">
                <div class="flex-row-social">
                    <div class="flex-2 footer-item-align-left">
                        <p class="copyright-text">Copyright &copy; 2024 All Rights Reserved by <b></b>
                        <a href="#">ReraLabs</a>.
                        </p>
                    </div>

                    <div class="flex-2 footer-item-align-right">
                        <ul class="social-icons">
                            
                        <li><a class="facebook" href="#"><img src="facebook.svg"/></a></li>
                        <li><a class="instagram" href="#"><img src="instagram.svg"/></a></li>
                        <li><a class="linkedin" href="#"><img src="linkedin.svg"/></a></li>
                        <li><a class="youtube" href="#"><img src="youtube.svg"/></a></li>   
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    );

}