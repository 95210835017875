import React, { Component } from 'react';

class AreaDropdownComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '', // Initially, no option is selected
    };
  }

  // Function to handle changes in the dropdown selection
  handleDropdownChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  // Function to handle form submission (just for demonstration)
  handleSubmit = (event) => {
    event.preventDefault();
    // console.log('Selected option:', this.state.selectedOption);
    // You can perform any further actions with the selected option here
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <label>
            Choose an option:
            <select value={this.state.selectedOption} onChange={this.handleDropdownChange}>
              <option value="">Select...</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </select>
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }
}

export default AreaDropdownComponent;