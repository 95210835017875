const landmarkMap = new Map();

landmarkMap.set('Burj Al Arab', {
    "intervalData": [
        "1998",
        "1999",
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024"
    ],
    "intervalTotals": {
        "2012": 10646.12,
        "2011": 10629.94,
        "2010": 10240.80,
        "1998": 1819.89,
        "2009": 9643.22,
        "2008": 11293.76,
        "2007": 11840.40,
        "2006": 10546.46,
        "2005": 9782.63,
        "2004": 5891.08,
        "2003": 1523.16,
        "2002": 1449.54,
        "2024": 25316.05,
        "1999": 1148.61,
        "2001": 1081.06,
        "2023": 26018.13,
        "2000": 1370.64,
        "2022": 22171.40,
        "2021": 16695.95,
        "2020": 16861.22,
        "2019": 15148.82,
        "2018": 16652.83,
        "2017": 15908.63,
        "2016": 14642.64,
        "2015": 15340.52,
        "2014": 15522.08,
        "2013": 12561.50
    }
});

landmarkMap.set('Hamdan Sports Complex',
    {
        "intervalData": [
            "2006",
            "2007",
            "2008",
            "2009",
            "2010",
            "2011",
            "2012",
            "2013",
            "2014",
            "2015",
            "2016",
            "2017",
            "2018",
            "2019",
            "2020",
            "2021",
            "2022",
            "2023",
            "2024"
        ],
        "intervalTotals": {
            "2012": 6215.21,
            "2023": 10823.34,
            "2011": 6734.91,
            "2022": 9458.85,
            "2010": 4868.06,
            "2021": 8685.23,
            "2020": 7563.10,
            "2009": 6009.38,
            "2008": 7462.12,
            "2019": 7856.56,
            "2007": 7072.70,
            "2018": 7732.06,
            "2006": 7278.63,
            "2017": 8004.57,
            "2016": 7587.93,
            "2015": 7684.90,
            "2014": 7488.29,
            "2013": 6019.25,
            "2024": 11443.50
        }
    }
);

landmarkMap.set('Burj Khalifa', {
    "intervalData": [
        "1997",
        "1998",
        "1999",
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024"
    ],
    "intervalTotals": {
        "2012": 16314.05,
        "2011": 15681.71,
        "2010": 16363.60,
        "1998": 4313.19,
        "2009": 16709.33,
        "1997": 4334.24,
        "2008": 19405.28,
        "2007": 19037.52,
        "2006": 13740.46,
        "2005": 8719.21,
        "2004": 6542.38,
        "2003": 4798.95,
        "2002": 4663.79,
        "2024": 32757.27,
        "1999": 4312.63,
        "2001": 5408.16,
        "2023": 23987.91,
        "2000": 4459.95,
        "2022": 21279.26,
        "2021": 17615.91,
        "2020": 18028.18,
        "2019": 19047.33,
        "2018": 18893.87,
        "2017": 19058.39,
        "2016": 19382.16,
        "2015": 20103.78,
        "2014": 20172.65,
        "2013": 19571.02
    }
});

landmarkMap.set('Al Makhtoum International Airport', {
    "intervalData": [
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024"
    ],
    "intervalTotals": {
        "2012": 5050.51,
        "2023": 10190.95,
        "2011": 2605.25,
        "2022": 6949.18,
        "2010": 2498.56,
        "2021": 6351.74,
        "2020": 6846.78,
        "2009": 3651.65,
        "2008": 4250.61,
        "2019": 7413.12,
        "2007": 8006.94,
        "2018": 8423.24,
        "2017": 7513.44,
        "2016": 6192.59,
        "2015": 8041.33,
        "2014": 14703.06,
        "2013": 7581.98,
        "2024": 13494.57
    }
});

landmarkMap.set('Dubai International Airport', {
    "intervalData": [
        "1993",
        "1997",
        "1998",
        "1999",
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024"
    ],
    "intervalTotals": {
        "2012": 7419.40,
        "2011": 6186.46,
        "2010": 6410.32,
        "1998": 3927.51,
        "2009": 6297.52,
        "1997": 7902.67,
        "2008": 8759.31,
        "2007": 11127.93,
        "2006": 9419.91,
        "2005": 6773.59,
        "1993": 1829.02,
        "2004": 4851.14,
        "2003": 3622.56,
        "2002": 3613.84,
        "2024": 17400.30,
        "1999": 3195.36,
        "2001": 3276.79,
        "2023": 16695.23,
        "2000": 2737.89,
        "2022": 13644.07,
        "2021": 10735.00,
        "2020": 10480.32,
        "2019": 12201.25,
        "2018": 10964.88,
        "2017": 12499.16,
        "2016": 12025.83,
        "2015": 12755.28,
        "2014": 10667.76,
        "2013": 8555.21
    }
});

landmarkMap.set('Global Village', {
    "intervalData": [
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024"
    ],
    "intervalTotals": {
        "2012": 6586.27,
        "2023": 15672.84,
        "2011": 5961.37,
        "2022": 13354.28,
        "2010": 5630.65,
        "2021": 10358.15,
        "2020": 8645.00,
        "2009": 5596.70,
        "2008": 5932.54,
        "2019": 10481.56,
        "2007": 4741.17,
        "2018": 8890.74,
        "2006": 7180.16,
        "2017": 9676.24,
        "2005": 7758.91,
        "2016": 8454.01,
        "2015": 9176.67,
        "2014": 9536.44,
        "2013": 6596.73,
        "2024": 17580.86
    }
});


landmarkMap.set('Gold', {
    "intervalTotals": {
        "1979": 1163.93597,
"1980": 2278.36795,
"1981": 1691.03194,
"1982": 1378.72895,
"1983": 1557.4579,
"1984": 1324.72272,
"1985": 1162.19778,
"1986": 1351.92474,
"1987": 1641.52338,
"1988": 1603.97099,
"1989": 1399.80516,
"1990": 1407.79204,
"1991": 1329.58386,
"1992": 1262.92339,
"1993": 1322.04967,
"1994": 1396.444,
"1995": 1410.56247,
"1996": 1424.56482,
"1997": 1216.71937,
"1998": 1080.16664,
"1999": 1023.13,
"2000": 1025.13,
"2001": 995.52,
"2002": 1137.45,
"2003": 1334.46,
"2004": 1502.93,
"2005": 1632.49,
"2006": 2217.63,
"2007": 2553.61,
"2008": 3202.61,
"2009": 3571.4,
"2010": 4497.58,
"2011": 5772.16,
"2012": 6130.18,
"2013": 5183.46,
"2014": 4651.52,
"2015": 4260.9,
"2016": 4594.17,
"2017": 4617.43,
"2018": 4659.14,
"2019": 5114.98,
"2020": 6499.7,
"2021": 6606.3,
"2022": 6611.71,
"2023": 7127.19,
"2024": 7851.31
    }
});

export default landmarkMap;

