export const DARK_THEME_TEXT_COLOR = "rgb(203, 196, 222)";
export const DARK_THEME_SELECTED_ELEMENT_COLOR = "rgb(35, 32, 32)";
export const DARK_THEME_NO_SELECT_ELEMENT_COLOR = "rgb(0,0,0)";

export const BACKEND_URL = "localhost"
export const colors = ['#582ad4', '#f0b811', '#EE6666'];
export const REAL_ESTATE_COLOR = '#582ad4';
export const GOLD_COLOR = '#f0b811';
export const RED_TO_GREEN_COLOR_PALETTE = [
    [0.05, "#fc0303"], 
    [0.1, "#fc1c03"], 
    [0.15, "#fc3503"],
    [0.20, "#fc5e03"],
    [0.25, "#fc7303"],
    [0.30, "#fca103"],
    [0.35, "#fcba03"],
    [0.40, "#fcce03"],
    [0.45, "#fce303"],
    [0.50, "#fcf403"],
    [0.55, "#ecf542"],
    [0.60, "#daf542"],
    [0.65, "#cbf542"],
    [0.70, "#bff542"],
    [0.75, "#b3f542"],
    [0.80, "#9cf542"],
    [0.85, "#8af542"],
    [0.90, "#72f542"],
    [0.95, "#5df542"],
    [1, '#42f55a']
];