import axios from 'axios'
import { Component, useState, useEffect } from 'react';
import AreaDropdownComponent from '../../components/AreaDropdownComponent';

import * as constants from '../../../core/constants';

import landmarkMap from '../../scheduler/getYearlyData';

export default function TimelineApi({updateChartData, landmark}) {

    async function fetchData() {
        // console.log("in timeapi")
          // console.log("landmark = " + landmark);
          const landmarkSelector = landmark;
          var intervalTotals;
          let data = JSON.stringify({
              "metrics": [
                "persquaremetrerate"
              ],
              "interval": "2000-01-01",
              "queryGranularity": "all",
              "filter": {
                "type": "and",
                "filters": [
                  {
                    "type": "contains",
                    "dimension": "landmarks",
                    "value": landmarkSelector
                  },
                  {
                    "type": "bound",
                    "dimension": "transactionYear",
                    "lower": "1990",
                    "lowerStrict": true,
                    "upper": 2090,
                    "upperStrict": true
                  }
                ]
              }
            });

            // console.log("landmark = " + landmarkSelector + " present in map ? " + landmarkMap.has(landmarkSelector));

            if (landmarkMap.has(landmarkSelector)) {
              // console.log("trying to find landmark =  " + landmark);
              // console.log('Fetch data from cache = ' + landmarkMap.get(landmark));
              intervalTotals = landmarkMap.get(landmark)['intervalTotals'];
            }

            else {
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `http://${process.env.REACT_APP_BACKEND_URL}:8083/v1/metrics/property/timeseries`,
                // url: `/v1/metrics/property/timeseries`,
                headers: { 
                  'Content-Type': 'application/json',
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                },
                data : data
              };


              try {
              // make a call to axios and set the values
              const response = await axios.request(config);
              intervalTotals = response.data['intervalTotals'];       
            }
            catch (error) {
              // do not handle right now
            };
          }
          // console.log(intervalTotals)
          const xAxisLocal = []
          const yAxisLocal = []
          const yAxisGoldLocal = []

          const goldData = landmarkMap.get('Gold')["intervalTotals"];

          for (const key in intervalTotals) {
              xAxisLocal.push(key);
              yAxisLocal.push(intervalTotals[key]);
              yAxisGoldLocal.push(goldData[key]);
          }




          // const {updateChartData} = this.props;
          updateChartData(xAxisLocal, yAxisLocal, yAxisGoldLocal, false);
          // setLoading(false);
          // console.log('------->>' + this.props);
          // console.log("data is now set " + xAxisLocal);
          // if (this.props.updateChartData) {
          //   this.props.updateChartData({xAxisLocal, yAxisLocal, true});
          // }
    }

    fetchData();

    return null;
    
}