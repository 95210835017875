import React, { useState } from 'react';
import ReactEChart from "echarts-for-react";

import landmarkMap from '../../scheduler/getYearlyData';
import '../InitalInvestmentForm/InitialInvestmentForm.css';

import * as constants from '../../../core/constants';

export default function InitialInvestmentForm({landmark, showCompare, updateChartData} ) {
    const [initialAmount, setinitialAmount] = useState(1000);
    const [goldFinalAmount, setGoldFinalAmount] = useState();
    const [realEstateFinalAmount, setRealEstateFinalAmount] = useState();

    // const [investmentYear, setinvestmentYear] = useState('');
    const [showResult, setShowResult] = useState(false);


    const totalIntervals = landmarkMap.get(landmark)["intervalData"];

    const landmarkValueMap = landmarkMap.get(landmark)["intervalTotals"];
    const initialYear = totalIntervals[0];
    const finalYear = totalIntervals[totalIntervals.length - 1];
    const goldDataMap = landmarkMap.get('Gold')["intervalTotals"];

    // console.log("Initial investment year = " + initialYear + " final year = " + finalYear) ;



    // dataMap = {"1978" : 100.80}
    function getGrowthPercentage(dataMap, initialYear, finalYear) {
        const startPrice = dataMap[initialYear];
        const endPrice = dataMap[finalYear];

        const percentChange = Math.floor(((endPrice - startPrice)/startPrice)*100);

        return percentChange;

    };

    function getGrowthPercentExact(dataMap, initialYear, finalYear) {
        const startPrice = dataMap[initialYear];
        const endPrice = dataMap[finalYear];

        const percentChange = ((endPrice - startPrice)/startPrice)*100;

        return percentChange;

    };

    const goldPercentage = getGrowthPercentage(goldDataMap, initialYear, finalYear);
    const realEstatePercentage = getGrowthPercentage(landmarkValueMap, initialYear, finalYear);

    const goldPercentExact = getGrowthPercentExact(goldDataMap, initialYear, finalYear);
    const realEstatePercentExact = getGrowthPercentExact(landmarkValueMap, initialYear, finalYear);


    function InvestmentReturn({goldPercentExact, realEstatePercentExact, initialAmount}) {
        const userInputAmount = parseFloat(initialAmount);
        const goldFinalAmountTmp = userInputAmount + (userInputAmount * goldPercentExact)/100;
        const realEstateFinalAmountTmp = userInputAmount + (userInputAmount * realEstatePercentExact)/100;
        setGoldFinalAmount(goldFinalAmountTmp);
        setRealEstateFinalAmount(realEstateFinalAmountTmp);
        // console.log("gold final amount = " + goldFinalAmount);
        // console.log("real estate final amount = " + realEstateFinalAmount);

        return(
            <div class="table">&nbsp;&nbsp;Final Investment Amount
            <div class="table-row header">
                <div class="table-cell">Gold</div>
                <div class="table-cell align-right">{parseFloat(parseFloat(goldFinalAmount).toFixed(2))}</div>
            </div>
            <div class="table-row">
                <div class="table-cell">Real Estate</div>
                <div class="table-cell align-right">{parseFloat(parseFloat(realEstateFinalAmount).toFixed(2))}</div>
            </div>
            </div>
        );
    }

    const gaugeOptionDummy = {
        series: [{
          name: "Indicator",
          type: "gauge",
        //   name: "Real Estate",
          axisLine: {
            lineStyle: {
                color: constants.RED_TO_GREEN_COLOR_PALETTE, // Color stops
                width: 10  // Adjust the thickness of the gauge needle
            }
        },
        pointer: {
            itemStyle: {
              color: constants.REAL_ESTATE_COLOR
            }
          },
          detail: {
            formatter: "{value}%",
            textStyle: {
                fontSize: 25,
                color: constants.DARK_THEME_TEXT_COLOR, // Change text color here
            }
          },
          data: [{
            value: 0 ,
            //name: "Real Estate"
          }],
          axisTick: {
            show: true
          },
          title: {
            color: constants.DARK_THEME_TEXT_COLOR,
            offsetCenter: [0, '70%']
          }
          
        }]
      };



    const gaugeOptionRealEstate = {
        series: [{
          name: "Indicator",
          type: "gauge",
        //   name: "Real Estate",
          axisLine: {
            lineStyle: {
                color: constants.RED_TO_GREEN_COLOR_PALETTE, // Color stops
                width: 10  // Adjust the thickness of the gauge needle
            }
        },
        pointer: {
            itemStyle: {
              color: constants.REAL_ESTATE_COLOR
            }
          },
          detail: {
            formatter: "{value}%",
            textStyle: {
                fontSize: 25,
                color: constants.DARK_THEME_TEXT_COLOR, // Change text color here
            }
          },
          data: [{
            value: realEstatePercentage ,
            name: "Real Estate"
          }],
          axisTick: {
            show: true
          },
          title: {
            color: constants.DARK_THEME_TEXT_COLOR,
            offsetCenter: [0, '70%']
          }
          
        }]
      };


    const gaugeOptionGold = {
        series: [{
          name: "Indicator",
          type: "gauge",
          axisLine: {
            lineStyle: {
                color: constants.RED_TO_GREEN_COLOR_PALETTE, // Color stops
                width: 10  // Adjust the thickness of the gauge needle
            }
        },
        pointer: {
            itemStyle: {
              color: constants.GOLD_COLOR
            }
          },
          detail: {
            formatter: "{value}%",
            textStyle: {
                fontSize: 25,
                color: constants.DARK_THEME_TEXT_COLOR, // Change text color here
            }
          },
          data: [{
            value: goldPercentage ,
            name: "Gold"
          }],
          axisTick: {
            show: true
          },
          title: {
            color: constants.DARK_THEME_TEXT_COLOR,
            offsetCenter: [0, '70%']
          }
          
        }]
      };;

    const handleinitialAmountChange = (e) => {
        const userInputAmount = e.target.value;
        setinitialAmount(e.target.value);
    };

    // const handleinvestmentYearChange = (e) => {
    //     setinvestmentYear(e.target.value);
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Calculate result or perform any desired operation
        const sum = parseFloat(initialAmount) + parseFloat(0);
        // console.log("now showing")
        setShowResult(true);
    };

    return (
        <div className="flex-dashboard">
            <div className="speedometer">
            {showResult ? <ReactEChart option={gaugeOptionRealEstate} 
                // style = {{height : "350px", fill: 'red'}}
                // style={{ height: "80vh", left: 50, top: 50, width: "90vw" }} 
                opts={{ renderer: "svg" }} /> : <ReactEChart option={gaugeOptionDummy} opts={{ renderer: "svg" }}/>}
            </div> 
            <div className="container-form">
            <form className="InvestmentForm" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="init-amount">
                        Initial Amount
                        <input className='user-initial-amount'
                            type="number"
                            value= {initialAmount}
                            min="10"
                            max="100000000"
                            defaultValue="1000"
                            onChange={handleinitialAmountChange}
                        />
                    </label>
                </div>
                <br />
                <div className="form-group">
                    <label>Investment Year <input class="user-initial-form"
                            type="number"
                            min="1990"
                            max="3099"
                            value= {initialYear}
                            disabled/>
                    </label>
                </div>
                <br />
                <button class = "submit-button" type="submit" onClick={handleSubmit}>Calculate Return</button>
                { showResult ? <InvestmentReturn goldPercentExact={goldPercentExact} 
                    realEstatePercentExact={realEstatePercentExact}
                    initialAmount={initialAmount} /> : null }
            </form>

            </div>
            
            <div className="speedometer">
            {showResult ? <ReactEChart option={gaugeOptionGold} 
                // style = {{height : "350px", fill: 'red'}}
                // style={{ height: "80vh", left: 50, top: 50, width: "90vw" }} 
                opts={{ renderer: "svg" }} /> : <ReactEChart option={gaugeOptionDummy} opts={{ renderer: "svg" }}/>}
            </div>
            
        </div>
    );
};

