import './App.css';

import { HashRouter, Routes, Route, Link } from 'react-router-dom';

import Timeline from './core/timeline';
import Header from './core/header';
import ImageCarousel from './features/components/BuyPage/ImageCarousel';
import Rental from './features/components/Rental';
import AuthForm from './features/components/AuthForm';
import { useState } from 'react';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';

import * as constants from './core/constants';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';
import Navbar from './features/components/Navbar/Navbar';
import Footer from './features/components/Footer/Footer';

function App() {

  const [theme, setTheme] = useState("dark");
  const [isOpen, setIsOpen] = useState(false);



  const navbarStyle = {
    backgroundColor: constants.DARK_THEME_TEXT_COLOR,
    width: "100%",
    height: "5px",
    borderRadius: "1px",
    display: "block",
    margin: "3px 0"
  };

  return (
    <div>
      <HashRouter>
        <div className="header">
        <Navbar isMenuShown={isOpen} setIsMenuShown={setIsOpen}/>
        </div>
         <Routes>
          <Route exact path = "/" element = {<ImageCarousel isMenuShown={isOpen} setIsMenuShown={setIsOpen}/>} />
          <Route exact path = "/buy" element = {<ImageCarousel isMenuShown={isOpen} setIsMenuShown={setIsOpen}/>} />
          <Route exact path = "/login" element = {<AuthForm/>} />
          <Route exact path = "/rent" element = {<Rental/>} />

          {/* <Route exact path = "/rent" element = {<Timeline/>} /> */}
         </Routes>
      </HashRouter>
      <Footer>
        
      </Footer>
    </div>

  );
}

export default App;
