import React, { useState } from "react";
import ReactEChart from "echarts-for-react";
import TimelineApi from "../features/timeline/apis/timelineApi";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";
import * as constants from '../core/constants';
import landmarkMap from "../features/scheduler/getYearlyData";


 // Specify the configuration items and data for the chart 


export default function Timeline({landmark, shouldReload}) {

  // console.log("Landmark passed in Timeline" + landmark);
  

  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const [yAxisGoldData, setYAxisGoldData] = useState([]);
  const [fetchData, setFetchData] = useState(shouldReload);
  const [currentLandmark, setCurrentLandmark] = useState(landmark);
  const [eChartsOption, setEchartsOption]  = useState({});

  if (landmark != currentLandmark){
    setFetchData(true);
    setCurrentLandmark(landmark)
  }

    const updateChartData = (xAxisData, yAxisData, yAxisGoldData, childFetchData) => {
      // console.log("Child component has sent =  " + yAxisData);
      const colors = ['#582ad4', '#f0b811', '#EE6666'];
      if (fetchData) {
        // console.log("Data not already set. FetchData = " + fetchData)
        setXAxisData(xAxisData);
        setYAxisData(yAxisData);
        setFetchData(childFetchData);
        setYAxisGoldData(yAxisGoldData);



        setEchartsOption({ 
          title: { text: '' },
           tooltip: {    
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            left: '10%'
          }, 
          legend: { 
            data: ['AED/m²', 'AED/t oz'],
            textStyle: {
            color: constants.DARK_THEME_TEXT_COLOR // Color of the legend text
            } 
          }, 
           xAxis: { data: xAxisData,
            axisLine: {
              lineStyle: {
                color: constants.DARK_THEME_TEXT_COLOR// Color of the axis line
              }
            },
            axisLabel: {
              textStyle: {
                color: constants.DARK_THEME_TEXT_COLOR // Color of the axis text
              }
            },
            axisTick: {
              alignWithLabel: true
            } 
          }, 
          //  yAxis: {}, 
          yAxis : [
              {
                type : 'value',
                position: 'left',
                alignTicks: true,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[0] // Color of the axis line
                  }
                },
                axisLabel : {
                  formatter: '{value}',
                  textStyle: {
                    color: constants.DARK_THEME_TEXT_COLOR  // Color of the axis text
                  }
                },
                name: 'Real Estate',
                nameLocation: 'middle',
                nameGap: 50
              },
              {
                type : 'value',
                name: 'Gold',
                position: 'right',
                alignTicks: true,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[1] // Color of the axis line
                  }
                },
                axisLabel : {
                  formatter: '{value} AED',
                  textStyle: {
                    color: constants.DARK_THEME_TEXT_COLOR // Color of the axis text
                  }
                },
                nameLocation: 'middle',
                nameGap: 20
              }
            ],
           series: [ { 
                      name: 'AED/m²', 
                      type: 'line', 
                      data: yAxisData ,
                      lineStyle: {color: colors[0]},
                      // itemStyle: {normal: {lineStyle: {type: 'default'}}},
                  },
                  { 
                    name: 'AED/t oz', 
                    type: 'line', 
                    data: yAxisGoldData,
                    itemStyle: {color: colors[1]}
                } ] 
          });
      }
      else{
        // console.log("Data already set. FetchData = " + childFetchData)
      }
        
      };

      // console.log("Value of reload = " + fetchData)
        
        return (
          <div>
                <TimelineApi
                updateChartData={updateChartData}
                landmark={landmark}
               />
                <ReactEChart option={eChartsOption} 
                style = {{height : "450px", fill: 'red'}}
                // style={{ height: "80vh", left: 50, top: 50, width: "90vw" }} 
                opts={{ renderer: "svg" }} /> 
            </div>
        )
    }
