import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAngleLeft, faAngleRight, faArrowLeft, faArrowRight, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useRef, useEffect } from 'react';
import Timeline from "../../../core/timeline";

import './ImageCarousel.css';
import ContactUs from "../contactUs/contactUs";
import InitialInvestmentForm from "../InitalInvestmentForm/InitialInvestmentForm";

const images = [
  '1_burj_al_arab_carousel.jpg', // Image: Mohd 1993 / Unsplash
  '2_global_village.jpg',
  '3_dubai_international_airport.jpg',
  '4_al_maktoum_international_airport.jpg',
  '5_burj_khalifa.jpg',
  '6_hamdan_sports_complex.jpg'
];

const landmarks = [
  'Burj Al Arab',
  'Global Village',
  'Dubai International Airport',
  'Al Makhtoum International Airport',
  'Burj Khalifa',
  'Hamdan Sports Complex'
]

export default function ImageCarousel({isMenuShown, setIsMenuShown}) {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [reloadChart, setReloadChart] = useState(true);
    const [showCompare, setShowCompare] = useState(false);

    const flexItemRefs = useRef([]);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [startPosition, setStartPosition] = useState(null);
  
    const handleTouchStart = (e) => {
      setStartPosition(e.touches[0].clientX);
      if (isMenuShown) {
        setIsMenuShown(false);
      }

    };
  
    const handleTouchMove = (event) => {
      const target = event.currentTarget;
      const id = target.id;
      if (id === "graph-1") {
        console.log("event = " + target);
        //ignoring the graph area
        setStartPosition(null);
        return;
      }
      else{
        console.log(typeof(id));
      }

      console.dir("event = " + id);
      if (startPosition === null) {
        return;
      }


      const currentPosition = event.touches[0].clientX;
      const difference = startPosition - currentPosition;
  
      if (Math.abs(difference) > 50) { // You can adjust the sensitivity here
        if (difference < 0) {
          handleSwipeRight();
        } else {
          handleSwipeLeft();
        }
        setStartPosition(null);
      }
    };
  
    const handleTouchEnd = () => {
      setStartPosition(null);
    };
  
    const handleSwipeLeft = () => {
      goToPrevSlide();
    };
  
    const handleSwipeRight = () => {
      goToNextSlide();
    };

    const closeMenuOnClick = (e) => {
      if (isMenuShown) {
        setIsMenuShown(false);
      }
    };

  

  const goToPrevSlide = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
    setReloadChart(true);
    setShowCompare(false);
    };


  const goToNextSlide = () => {
    setCurrentImageIndex((currentImageIndex + 1 + images.length) % images.length);
    setReloadChart(true);
    setShowCompare(false);
  };
  // 0, 1, 2 len = 3 
  // (0-1) + 3 % 3 = 2
  // (0+1) + 3 % 3 = 1

  const updateCompareCharts = (showCompare) => {
    setShowCompare(showCompare);
  }

    const landmark = landmarks[currentImageIndex];
    // console.log( "User seeing ="  + landmark);

    return (
      <div className="pageContainer" onClick={closeMenuOnClick} 
        onTouchStart={handleTouchStart} 
        onTouchMove={handleTouchMove} 
        onTouchEnd={handleTouchEnd}>

        <div className="flex-image-carousel"> 
          <div className="part-1">
            <button className="nav-btn prev-btn" onClick={goToPrevSlide}> <FontAwesomeIcon icon={faAngleLeft} /> </button>
          </div>
          
          <div className="part-2" ref={el => flexItemRefs.current.push(el)}>
            <img className="carousel-image" src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} /> 
          </div>

          <div className="part-3" id="graph-1" onMouseEnter={handleTouchMove}> 
            {/* {console.log("current element == " + flexItemRefs.current)} */}
            <h3 className="chartHeader">Avg. AED/m² near {landmark}</h3>
            {reloadChart && <Timeline landmark={landmark} shouldReload={reloadChart}/>}
          </div>

          <div className="part-4">
            <button className="nav-btn next-btn" onClick={goToNextSlide}> <FontAwesomeIcon icon={faAngleRight} /> </button>
          </div>
        </div>

        <div className="flex-dashboard-parent">
          <div className="part-1"></div>
          <div className="item-dashboard">
              <InitialInvestmentForm landmark={landmark} showCompare={showCompare} updateCompareCharts={updateCompareCharts}/>
          </div>

          <div className="part-4">
          </div>
        </div>
      </div>
    );
  }
