import { Link } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faHouse, faKey, faKeyboard, faLocation, faHamburger } from "@fortawesome/free-solid-svg-icons";

import './Navbar.css';

import * as constants from "../../../core/constants";


export default function Navbar({isMenuShown, setIsMenuShown}) {

    const burgerStyle = {
        backgroundColor: constants.DARK_THEME_TEXT_COLOR,
        width: "100%",
        height: "5px",
        borderRadius: "1px",
        display: "block",
        margin: "3px 0"
      };

    const onSelectStyle = {
        backgroundColor: constants.DARK_THEME_SELECTED_ELEMENT_COLOR   
    };

    const noSelectionStyle = {
        backgroundColor: constants.DARK_THEME_NO_SELECT_ELEMENT_COLOR
    }

    const handleToggle = () => {
        if (isMenuShown){
            setIsMenuShown(false);
        }
        else{
        setIsMenuShown(true);
        }
    };

    return ( 
        <div className="navigation-flex-container"> 

            <div className="navbar-logo navbar-item flex-50"><Link to="/buy"> <img className="reralabs-logo" src="reralabsgreen.svg"/></Link> </div> 
            <div className={isMenuShown ? "navbar-toggle navbar-item flex-50 align-right" :"navbar-toggle navbar-item flex-50 align-right"}>
                {/* <div><Link to="/buy" className="navbar-logo"> <img src="reralabsgreen.svg"/></Link></div> */}
                <div className="flex-empty-50"></div>
                <div style = {isMenuShown ? onSelectStyle : noSelectionStyle}>
                    <div  className="navbar-burger-icon" onClick={handleToggle}>         
                    <span style={burgerStyle}></span>
                    <span style={burgerStyle}></span>
                    <span style={burgerStyle}></span>
                    </div>
                </div>
            </div>
            <div className="navbar-item"></div>
            <div style = {isMenuShown ? onSelectStyle : noSelectionStyle} 
            className={isMenuShown ? "navbar navbar-mobile active navbar-item flex-full-width" : "navbar navbar-mobile inactive navbar-item"}>
                    <ul> 
                    <li className="float-left"><Link to="/" className="mylink themeDark"> <FontAwesomeIcon icon={faHouse} />Home</Link></li>
                    <li className="float-left"><Link to="/buy" className="mylink themeDark"> <FontAwesomeIcon icon={faLocation} />Buy</Link></li>
                    {/* <li><Link to="/rent" className="mylink themeDark"> <FontAwesomeIcon icon={faBuilding} />Rent</Link></li> */}
                    <li className="float-left"><Link to="/signup" className="mylink themeDark"> <FontAwesomeIcon icon={faKeyboard} />Signup</Link></li>
                    <li className="float-left"><Link to="/login" className="mylink themeDark"><FontAwesomeIcon icon={faKey} />Login</Link></li>
                    </ul>
            </div>
        </div>
    );

}